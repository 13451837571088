<template>
  <v-menu v-if="userDetail" offset-y left nudge-bottom="14" min-width="230" content-class="user-profile-menu-content">
    <template v-slot:activator="{ on, attrs }">
      <v-list v-if="!$vuetify.breakpoint.xsOnly" color="transparent" dense nav>
        <v-list-item v-bind="attrs" v-on="on">
          <v-list-item-avatar>
            <v-avatar size="40px" color="primary" class="v-avatar-light-bg primary--text">
              <v-icon color="primary" size="40">
                {{ icons.mdiAccountCircle }}
              </v-icon>
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-subtitle class="text-caption"> Kullanıcı </v-list-item-subtitle>
            <v-list-item-title class="text-overline">
              {{ userData.username }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action @click="logoutUser">
            <v-btn icon large>
              <v-icon>{{ icons.mdiLogout }}</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-avatar v-else size="40px" v-bind="attrs" color="primary" class="v-avatar-light-bg primary--text" v-on="on">
        <v-icon color="primary" size="40px">
          {{ icons.mdiInformation }}
        </v-icon>
      </v-avatar>
    </template>
    <v-list>
      <v-list-item three-line style="height: auto">
        <v-list-item-avatar tile>
          <v-icon size="30">
            {{ icons.mdiDomain }}
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="text-body-2 font-weight-medium mt-1">Şirket</v-list-item-title>
          <v-list-item-subtitle class="text-caption"> Şirket Kodu : {{ userDetail.company }} </v-list-item-subtitle>
          <v-list-item-subtitle class="text-caption"> Kullanıcı Kodu : {{ userData.id }} </v-list-item-subtitle>
          <v-list-item-subtitle class="text-caption">
            {{ userDetail.email }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <!--   <v-divider></v-divider>
      <v-list-item three-line style="height: auto">
        <v-list-item-avatar tile>
          <v-icon size="30">
            {{ icons.mdiAccountVoice }}
          </v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-subtitle>Satış Temsilcisi</v-list-item-subtitle>
          <v-list-item-title class="text-body-2 font-weight-medium mt-1">
            {{ userDetail.sales_person }}
          </v-list-item-title>
          <v-list-item-subtitle class="text-caption">
            {{ userDetail.sales_person_email }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item> -->

      <!--  <v-divider></v-divider>
      <v-list-item three-line style="height: auto">
        <v-list-item-avatar tile>
          <v-icon size="30">
            {{ icons.mdiMapMarker }}
          </v-icon>
        </v-list-item-avatar>

           <v-list-item-content>
          <v-list-item-subtitle>Konum</v-list-item-subtitle>
          <v-list-item-title class="text-body-2 font-weight-medium mt-1">
            {{ userDetail.city }} / {{ userDetail.state }} - {{ userDetail.country }}
          </v-list-item-title>
          <v-list-item-subtitle class="text-caption"> Ofis Kodu : {{ userDetail.office_code }} </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item> -->
      <v-divider v-if="$vuetify.breakpoint.xsOnly"></v-divider>

      <!-- Logout -->
      <v-list-item v-if="$vuetify.breakpoint.xsOnly" three-line style="height: auto" @click="logoutUser">
        <v-list-item-avatar tile>
          <v-icon size="30">
            {{ icons.mdiLogout }}
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Çıkış Yap</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { initialAbility } from '@/plugins/acl/config'
import CompanyList from '@/views/CompanyList.vue'
import axios from '@axios'
import { useRouter } from '@core/utils'
import {
  mdiAccountCircle,
  mdiAccountVoice,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiDomain,
  mdiHelpCircleOutline,
  mdiInformation,
  mdiLogout,
  mdiMapMarker,
} from '@mdi/js'
import { getCurrentInstance, nextTick, onMounted, ref } from '@vue/composition-api'

export default {
  components: {
    CompanyList,
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { router } = useRouter()
    const userData = JSON.parse(localStorage.getItem('userData'))
    const b = localStorage.getItem('b')
    const k = localStorage.getItem('k')
    const s = localStorage.getItem('s')

    const autoLogin = () => {
      const params = new URLSearchParams()
      params.append('method', 'login')
      params.append('UserName', k)
      params.append('Password', s)
      params.append('web_api', 1)
      axios.post('', params).then(response => {
        if (response.data.error) {
          return []
        }
        const { accessToken } = response.data
        localStorage.setItem('accessToken', accessToken)

        const { user } = response.data
        const { ability: userAbility } = user

        vm.$ability.update(userAbility)
        localStorage.setItem('userAbility', JSON.stringify(userAbility))
        delete user.ability

        // Set user's data in localStorage for UI/Other purpose
        localStorage.setItem('userData', JSON.stringify(user))

        window.location.href = '/'
      })
    }

    const logoutUser = () => {
      // Remove userData from localStorage
      // ? We just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem('accessToken')

      // Remove userData & Ability from localStorage
      localStorage.removeItem('b')
      localStorage.removeItem('k')
      localStorage.removeItem('s')

      localStorage.removeItem('userData')
      localStorage.removeItem('userAbility')

      // Reset ability
      vm.$ability.update(initialAbility)

      // Redirect to login page
      // router.push({ name: 'auth-login' })
      router.push('/login')
    }

    const userDetail = ref(null)
    onMounted(() => {
      const params = new URLSearchParams()
      params.append('method', 'user_info')
      params.append('web_api', 1)
      axios
        .post('', params)
        .then(response => {
          if (response.data.error) {
            logoutUser()

            return
          }
          debugger
          // nextTick callback fonksiyonuyla kullanılabilir
          nextTick().then(() => {
            userDetail.value = response.data.response
            console.log('Component has been rendered and updated')
          })
        })
        .catch(e => {
          console.log(b, k, s)
          if (b && k && s) {
            console.log(1)
            autoLogin()
          } else {
            console.log(2)
            logoutUser()
          }
        })
    })

    return {
      logoutUser,
      userData,
      userDetail,
      icons: {
        mdiInformation,
        mdiDomain,
        mdiAccountVoice,
        mdiMapMarker,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogout,
        mdiAccountCircle,
      },
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}

.v-avatar-light-bg {
  position: absolute;
  bottom: 5px; /* Ekranın altından 20px yukarıda */
  right: 45px; /* Ekranın sağından 20px uzaklıkta */
}
.v-avatar-light-bg2 {
  position: absolute;
  bottom: 5px; /* Ekranın altından 20px yukarıda */
  left: 5px; /* Ekranın sağından 20px uzaklıkta */
}
.v-icon {
  font-size: 40px;
  color: var(--v-primary-base);
}
</style>

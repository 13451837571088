<template>
  <v-sheet :class="!styleTheme ? 'ma-3' : null" :color="styleTheme ? 'grey' : 'transparent'">
    <v-autocomplete
      v-model="company"
      label="Şirket"
      placeholder="Şirket Seçin"
      persistent-placeholder
      :items="companyItems"
      :loading="companyLoading"
      :filled="styleTheme"
      :background-color="styleTheme ? 'grey' : 'transparent'"
      color="white"
      hide-details
      :rounded="styleTheme"
      item-text="company_name"
      item-value="company"
      @change="companyChange"
      class="custom-autocomplete"
    >
      <template v-if="!$vuetify.breakpoint.mdAndDown" v-slot:prepend>
        <v-icon>{{ icons.mdiDomain }}</v-icon>
      </template>
      <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title class="white--text font-weight-bold custom-title">
            {{ item.company_name }}
            <v-list-item-title class="black--text font-weight-bold custom-subtitle">
              Şirket No: {{ item.company }}
            </v-list-item-title>
          </v-list-item-title>
          <!-- <v-list-item-subtitle>
            {{ item.address || 'Adres Bilgisi Yok' }}
          </v-list-item-subtitle> -->
        </v-list-item-content>
        <!-- <v-list-item-action>
          <v-chip :color="item.islocked == 0 ? 'success' : 'error'" label>
            {{ item.islocked == 0 ? 'Aktif' : 'Pasif' }}
          </v-chip>
        </v-list-item-action> -->
      </template>
    </v-autocomplete>
  </v-sheet>
</template>

<script>
import store from '@/store'
import axios from '@axios'
import { mdiDomain } from '@mdi/js'
import Vue from 'vue'
export default {
  props: {
    styleTheme: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      company: null,
      companyItems: [],
      companyLoading: false,
      icons: { mdiDomain },
    }
  },
  mounted() {
    this.companyList()
  },
  methods: {
    async companyList() {
      this.companyLoading = true
      const params = new URLSearchParams()
      params.append('method', 'getCompanies')
      params.append('web_api', 1)
      try {
        const response = await axios.post('', params)
        if (response.data.error_code === 99) {
          Vue.swal({
            title: 'Oturum Sonlandı!',
            html: 'Geçersiz Token',
            icon: 'error',
            background: '#FF4C51',
            timerProgressBar: true,
            showConfirmButton: false,
          })
          this.$router.push('/login')
          return
        }
        this.companyItems = response.data.response
        this.company = store.state.companyCustomer
      } catch (e) {
        console.log(e)
        Vue.swal({
          title: 'Hata',
          text: e.message,
          width: '350px',
          icon: 'error',
          background: '#FF4C51',
          showCloseButton: false,
          showConfirmButton: true,
          confirmButtonColor: '#cc3d41',
        })
      } finally {
        this.companyLoading = false
      }
    },
    companyChange() {
      try {
        debugger
        this.$emit('company-changed', this.company)
        store.commit('COMPANY_CUSTOMER', this.company)
        console.log(this.company)
        this.$router.push({ name: 'dashboard' })
      } catch (error) {
        console.error('Hata:', error)
        Vue.swal({
          title: 'Hata',
          text: error.message,
          icon: 'error',
          background: '#FF4C51',
        })
      }
    },
  },
}
</script>
<style lang="scss">
.custom-autocomplete {
  width: 600px; /* Set the desired width here */
}

.v-menu__content:not(.list-style) .v-list-item {
  height: auto !important;
}

/* Varsayılan stil ayarları */
.custom-title {
  font-size: 15px; /* Masaüstü cihazlar için font boyutu */
  color: white; /* Varsayılan yazı rengi */
}

.custom-subtitle {
  font-size: 12px; /* Masaüstü cihazlar için alt başlık font boyutu */
  color: black; /* Varsayılan yazı rengi */
}

/* Mobil ekranlar için stil ayarları */
@media (max-width: 600px) {
  .custom-title {
    font-size: 10px; /* Mobil cihazlarda font boyutunu küçültür */
  }

  .custom-subtitle {
    font-size: 8px; /* Mobil cihazlarda alt başlık font boyutunu küçültür */
  }
  .custom-autocomplete {
    width: 600px; /* Set the desired width here */
    font-size: 10px;
  }
}
</style>

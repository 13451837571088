import { mdiBank, mdiBankTransfer, mdiHomeOutline, mdiLogout } from '@mdi/js'

export default [
  {
    title: 'Gösterge Paneli',
    icon: mdiHomeOutline,
    to: 'dashboard',
  },

  /*  {
    subheader: 'Sipariş',
  },
  {
    title: 'Sipariş Oluşturma',
    icon: mdiTableLargePlus,
    to: 'order-create',
  },
  {
    title: 'Stok Siparişler',
    icon: mdiSunglasses,
    to: 'order-stok',
  },
  {
    title: 'Lens Siparişler',
    icon: mdiCircle,
    to: 'order-lens',
  }, */

  {
    subheader: 'Bankalar',
  },
  {
    title: 'Banka Hesapları',
    icon: mdiBank,
    to: 'accounts-list',
  },
  {
    title: 'Hesap Hareketleri',
    icon: mdiBankTransfer,
    to: 'account-transactions-list',
  },
  {
    subheader: 'Çıkış',
  },
  {
    title: 'Çıkış Yap',
    icon: mdiLogout,
    to: 'logout',
    showOnMobile: true, // Mobilde gösterim için eklenen özellik
  },

  /*  {
    title: 'Ekstre',
    icon: mdiTrayFull,
    to: 'extarct-list',
  }, */
]

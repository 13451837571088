<template>
  <layout-content-vertical-nav :nav-menu-items="navMenuItems">
    <slot></slot>

    <!-- Slot: Navbar -->
    <template #navbar="{ isVerticalNavMenuActive, toggleVerticalNavMenuActive }">
      <div class="d-flex align-center justify-space-between">
        <v-sheet
          v-if="!$vuetify.breakpoint.xsOnly"
          class="flex-grow-1 ml-md-3 overflow-hidden rounded-pill custom-autocomplete"
          style="width: auto"
        >
          <company-list :style-theme="true" />
        </v-sheet>

        <v-sheet
          v-if="$vuetify.breakpoint.xsOnly"
          class="flex-grow-1 ml-md-3 rounded-pill custom-autocomplete"
          style="width: auto"
        >
          <company-list :style-theme="false" class="positioned-list pb-3" />
        </v-sheet>
      </div>

      <div class="navbar-content-container" :class="{ 'expanded-search': shallShowFullSearch }">
        <!-- Left Content: Search -->
        <div class="d-flex align-center">
          <router-link v-if="$vuetify.breakpoint.mdAndDown" to="/" class="d-flex align-center">
            <img :src="require('@/assets/images/mavera_img.png')" alt="logo" height="50" class="app-logo" />
          </router-link>
          <!-- <app-bar-search
            :shall-show-full-search.sync="shallShowFullSearch"
            :data="appBarSearchData"
            :filter="searchFilterFunc"
            :search-query.sync="appBarSearchQuery"
            @update:shallShowFullSearch="handleShallShowFullSearchUpdate(isVerticalNavMenuActive, toggleVerticalNavMenuActive)"
          ></app-bar-search> -->
        </div>

        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class="d-flex align-center right-row">
          <app-bar-user-menu></app-bar-user-menu>
          <v-icon v-if="$vuetify.breakpoint.mdAndDown" class="ms-3" @click="toggleVerticalNavMenuActive">
            {{ icons.mdiMenu }}
          </v-icon>
        </div>
      </div>
    </template>
  </layout-content-vertical-nav>
</template>

<script>
// App Bar Components
import AppBarUserMenu from '@core/layouts/components/app-bar/AppBarUserMenu.vue'
import { mdiHeartOutline, mdiMenu } from '@mdi/js'
import themeConfig from '@themeConfig'

import { getVuetify } from '@core/utils'

// Search Data
import appBarSearchData from '@/assets/app-bar-search-data'
import { ref, watch } from '@vue/composition-api'

import LayoutContentVerticalNav from '@/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import navMenuItems from '@/navigation/vertical'
import CompanyList from '@/views/CompanyList.vue'

export default {
  components: {
    LayoutContentVerticalNav,

    // App Bar Components
    AppBarUserMenu,
    CompanyList,
  },
  setup() {
    const $vuetify = getVuetify()

    // Search
    const appBarSearchQuery = ref('')
    const shallShowFullSearch = ref(false)
    const maxItemsInGroup = 5
    const totalItemsInGroup = ref({
      pages: 0,
      files: 0,
      contacts: 0,
    })
    watch(appBarSearchQuery, () => {
      totalItemsInGroup.value = {
        pages: 0,
        files: 0,
        contacts: 0,
      }
    })
    const searchFilterFunc = (item, queryText, itemText) => {
      if (item.header || item.divider) return true

      const itemGroup = (() => {
        if (item.to !== undefined) return 'pages'
        if (item.size !== undefined) return 'files'
        if (item.email !== undefined) return 'contacts'

        return null
      })()

      const isMatched = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1

      if (isMatched) {
        if (itemGroup === 'pages') totalItemsInGroup.value.pages += 1
        else if (itemGroup === 'files') totalItemsInGroup.value.files += 1
        else if (itemGroup === 'contacts') totalItemsInGroup.value.contacts += 1
      }

      return appBarSearchQuery.value && isMatched && totalItemsInGroup.value[itemGroup] <= maxItemsInGroup
    }

    // ? Handles case where in <lg vertical nav menu is open and search is triggered using hotkey then searchbox is hidden behind vertical nav menu overlaty
    const handleShallShowFullSearchUpdate = (isVerticalNavMenuActive, toggleVerticalNavMenuActive) => {
      if ($vuetify.breakpoint.mdAndDown && isVerticalNavMenuActive) {
        toggleVerticalNavMenuActive()
      }
    }

    return {
      navMenuItems,
      handleShallShowFullSearchUpdate,

      // App Config
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Search
      appBarSearchQuery,
      shallShowFullSearch,
      appBarSearchData,
      searchFilterFunc,

      icons: {
        mdiMenu,
        mdiHeartOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}
.custom-autocomplete {
  width: 100%; /* Tam genişlik */
  max-width: 100%; /* Maksimum genişlik */
  overflow-x: auto; /* Yatay kaydırma ekleyin */
}

.app-logo {
  height: 40px; /* Logo boyutunu mobil ekranlarda uygun şekilde ayarlayın */
}

.right-row {
  display: flex;
  align-items: center;
  gap: 10px; /* Sağdaki öğeler arasına boşluk ekleyin */
}
.navbar-content-container {
  @media (max-width: 600px) {
    padding: 10px;
  }
}

/* Küçük ekranlar için metni göstermek */
@media (max-width: 600px) {
  .custom-autocomplete {
    /* CompanyList bileşeninin mobil ekranlarda daha dar görünmesini sağlar */
    max-width: 40%; /* veya ihtiyaca göre ayarlayın */

    z-index: 50; /* Menüden daha yüksek bir değer */
  }
  .app-logo {
    height: 20px; /* Mobil ekranlarda daha küçük logo */
  }

  .right-row {
    display: flex;
    flex-direction: column; /* Sağdaki içerikleri dikeyde düzenleyin */
    align-items: flex-start; /* İçerikleri sola hizalayın */
  }
}

// ? Handle bg of autocomplete for blured appBar
.v-app-bar.bg-blur {
  .expanded-search {
    ::v-deep .app-bar-autocomplete-box div[role='combobox'] {
      background-color: transparent;
    }

    > .d-flex > button.v-icon {
      display: none;
      //display: flex; /* veya display: inline; display: flex; display: grid; */
    }

    // ===

    & > .right-row {
      //visibility: visible;
      visibility: hidden; /* Sağdaki içerikleri görünür yapın */
      opacity: 0; /* Sağdaki içerikleri görünür yapın */
    }

    ::v-deep .app-bar-search-toggler {
      visibility: hidden;
    }
  }
}

.positioned-list {
  position: absolute;
  top: 45px; /* Yüksekliğin ortasına yerleştirir */
  left: 170px; /* Genişliğin ortasına yerleştirir */
  transform: translateX(-50%); /* Ortalamayı yapar */
  width: 350px;
  padding: 15px; /* Kenarlarda boşluk bırakır */
  box-sizing: border-box; /* Padding ve border'ı genişliğe dahil eder */

  overflow-y: auto; /* Dikey kaydırma ekler */

  z-index: 3000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Gölge ekleyin */
}

.parent {
  position: relative; /* Çocuk öğe için referans noktası */
  width: 1000px;
  height: 150px;
  background-color: lightgray;
  overflow: hidden; /* Çocuğun taşmasını engeller */
}

.child {
  position: relative; /* Normal akışa göre kaydırılır */
  top: 10px; /* Yukarıya 20px kaydır */
  left: 30px; /* Sola 30px kaydır */
  background-color: coral;
  z-index: 10; /* Yığılma sırası */
}
</style>

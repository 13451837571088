import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import app from './app'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    companyCustomer: null,
  },
  mutations: {
    COMPANY_CUSTOMER(state, value) {
      state.companyCustomer = value
    },
  },
  plugins: [createPersistedState()],
  actions: {},
  modules: {
    appConfig: appConfigStoreModule,
    app,
  },
})

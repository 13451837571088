import { canNavigate } from '@/plugins/acl/routeProtection'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // ? We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.
  {
    path: '/',
    redirect: to => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      const userRole = userData && userData.role ? userData.role : null
      if (userRole === 'admin') return { name: 'dashboard' }

      return { name: 'auth-login', query: to.query }
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },

  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/reset-password',
    name: 'auth-reset-password',
    component: () => import('@/views/ResetPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/NotAuthorized.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/dashboards/',
    name: 'dashboard',
    component: () => import('@/views/dashboards/index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/order/stok',
    name: 'order-stok',
    component: () => import('@/views/order/list/OrderStok.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/order/lens',
    name: 'order-lens',
    component: () => import('@/views/order/list/OrderLens.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/order/create',
    name: 'order-create',
    component: () => import('@/views/order/create/OrderCreate.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/accounts/list',
    name: 'accounts-list',
    component: () => import('@/views/accounts/AccountsList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/accounts/transactions/list',
    name: 'account-transactions-list',
    component: () => import('@/views/accountTransactions/AccountTransactionsList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/accounts/transactions/list/:id',
    name: 'account-transactions-list',
    component: () => import('@/views/accountTransactions/AccountTransactionsList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/extarct',
    name: 'extarct-list',
    component: () => import('@/views/extarct/ExtarctList.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '*',
    redirect: 'error-404',
  },

  {
    path: '/login',
    name: 'logout',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: false,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})
/*
// Mobile check utility function
function isMobile() {
  return window.innerWidth < 768 // Or use your own mobile breakpoint
}
 */
// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach((to, _, next) => {
  const userData = localStorage.getItem('userData')
  const isLoggedIn = userData && localStorage.getItem('accessToken') && localStorage.getItem('userAbility')

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) {
      return next({ name: 'auth-login', query: { marketplace: to.query.marketplace } })
    }
    localStorage.removeItem('accessToken')
    localStorage.removeItem('userData')
    localStorage.removeItem('userAbility')

    // return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/')
  }

  /*  // Mobile-specific handling for the logout route
  if (to.name === 'logout' && !isMobile()) {
    return next('/') // Redirect to home if not mobile
  } */

  return next()
})

export default router

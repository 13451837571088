// axios
import axios from 'axios'
import Vue from 'vue'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: 'https://ws.onlinehesaphareketleri.com/',
  timeout: 300000000,
})

axiosIns.interceptors.request.use(
  config => {
    // Do something before request is sent

    const accessToken = localStorage.getItem('accessToken')
    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

    return config
  },
  error => Promise.reject(error),
)

// Add a response interceptor
axiosIns.interceptors.response.use(
  response => {
    if (response.data.error === 99) {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('userData')
      localStorage.removeItem('userAbility')
      window.location.href = '/'
    }

    return response
  },
  error =>
    // localStorage.removeItem('accessToken')
    // localStorage.removeItem('userData')
    // localStorage.removeItem('userAbility')
    // window.location.href = '/'
    // Do something with response error
    Promise.reject(error),
)

Vue.prototype.$http = axiosIns

export default axiosIns
